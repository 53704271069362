body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* HomepageV2.css */

/* Reset default margins and ensure full viewport coverage */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.fullscreen-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url('/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f3f4f6;
    color: #1f2937;
  }

  .App {
    all: unset;
  }

  main {
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    max-width: 48rem;
    width: 100%;
    padding: 2rem;
    filter: blur(8px);
    -webkit-filter: blur(8px); /* For Safari support */
  }

  h1 {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  h1 span, h1 a span {
    color: #3b82f6;
  }

  h1 a {
    color: inherit;
    text-decoration: none;
  }

  h1 a:hover {
    color: #2563eb;
  }

  p {
    margin-bottom: 1rem;
  }

  .disclaimer {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1.5rem;
  }

  section {
    margin-bottom: 2rem;
  }

  blockquote {
    border-left: 4px solid #3b82f6;
    padding-left: 1rem;
    font-style: italic;
    margin-bottom: 2rem;
  }

  blockquote footer {
    text-align: right;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #3b82f6;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .social-links {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .social-links a {
    color: #4b5563;
  }

  .social-links a:hover {
    color: #3b82f6;
  }

  .centered-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 1.2rem;
    text-align: center;
    max-width: 80%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
  }

